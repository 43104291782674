import { onReducer } from "../core.state.commons"
import { getEnvionment } from "../forcepoint-platform-coreui"
import { ActionState } from "./core.state"

/**
 * Core Reducer
 * @param initialState
 */
export function CoreReducer(initialState) {
  /**
   * Action to set applications
   */
  onReducer(ActionState.SET_APPLICATIONS, (state = initialState, action) => {
    return {
      ...state,
      applications: action.applications,
      environment: getEnvionment()
    }
  })

  /**
   * To set active application, menu and pagetitle.
   */
  onReducer(ActionState.SET_ACTIVE_APP, (state = initialState, action) => {
    let activeApp: any
    const mountedApps: any[] = []
    const getCurrentAppJson = state.applications?.find((a) => {
      if (a.isSPA) {
        activeApp = { ...a }
        for (const module of a.modules) {
          if (action.appName?.includes(module.SPAName)) {
            mountedApps.push(module)
          }
        }
        const moduleName = a.modules.find(
          (b) => action.appName?.includes(b.SPAName) && !b.location
        )
        if (moduleName !== undefined) {
          activeApp.modules = moduleName
        } else {
          activeApp = null
        }
        return moduleName
      }
    })

    const activeMenu = activeApp?.menus?.find(
      (a) => window.location.pathname === a.url
    )
    const pageTitle: string = activeMenu?.pageTitle
    const appTitle: string = activeApp?.applicationName

    const settingsMenu = state.applications?.reduce((a, b) => {
      const settings = b.settings && b.settings.length ? [...b.settings] : []
      return [...a, ...settings]
    }, [])

    return {
      ...state,
      activeApp,
      mountedApps,
      activeMenu,
      pageTitle,
      settingsMenu,
      appTitle
    }
  })

  /**
   * To set actice menu
   */
  onReducer(ActionState.SET_ACTIVE_MENU, (state = initialState, action) => {
    const activeMenu = state.isSettingsPage
      ? state?.settingsMenu?.find((a) => action.url === a.url)
      : state?.activeApp?.menus?.find((a) => action.url === a.url)
    if (activeMenu) {
      return { ...state, activeMenu }
    }
    return { ...state }
  })

  /**
   * To set user token
   */
  onReducer(ActionState.SET_USER_TOKEN, (state = initialState, action) => {
    return {
      ...state,
      userToken: action.userToken
    }
  })

  /**
   * To set id token
   */
  onReducer(ActionState.SET_ID_TOKEN, (state = initialState, action) => {
    return {
      ...state,
      idToken: action.idToken
    }
  })

  /**
   * To set user info
   */
  onReducer(ActionState.SET_USER_INFO, (state = initialState, action) => {
    const userResponse = action.userResponse
    return {
      ...state,
      userInfo: userResponse
    }
  })

  /**
   * To logout app
   */
  onReducer(
    ActionState.SET_APP_LOGOUT_SUCCESS,
    (state = initialState, action) => {
      return {
        ...state,
        applications: [],
        activeApp: null,
        initialLoad: true,
        userToken: null,
        companyId: null,
        userInfo: null,
        interAppData: null,
        showGlobalLoader: false,
        showWhiteLoader: true,
        appTitle: null,
        pageTitle: null,
        mountedApps: [],
        activeMenu: null,
        settingsMenu: [],
        isSettingsPage: false,
        applicationMessages: {},
        eulaConfig: null,
        idToken: null
      }
    }
  )

  /**
   * To set inter app data
   */
  onReducer(ActionState.SET_INTER_APP_DATA, (state = initialState, action) => {
    return {
      ...state,
      interAppData: action.interAppData
    }
  })

  /**
   * To set app title
   */
  onReducer(ActionState.SET_PAGE_TITLE, (state = initialState, action) => {
    return {
      ...state,
      pageTitle: action.pageTitle
    }
  })

  /**
   * To set page title
   */
  onReducer(ActionState.SET_APP_TITLE, (state = initialState, action) => {
    return {
      ...state,
      appTitle: action.appTitle
    }
  })

  /**
   * To set loader status
   */
  onReducer(ActionState.SET_LOADER_STATUS, (state = initialState, action) => {
    return {
      ...state,
      showGlobalLoader: action.status
    }
  })

  /**
   * To set settings status
   */
  onReducer(ActionState.SET_SETTINGS_TYPE, (state = initialState, action) => {
    return {
      ...state,
      isSettingsPage: action.status
    }
  })

  /**
   * To set application messages
   */
  onReducer(
    ActionState.SET_APPLICATION_MESSAGES_SUCCESS,
    (state = initialState, action) => {
      return {
        ...state,
        applicationMessages: action.messages
      }
    }
  )

  /**
   * To get pending EULAS
   */
  onReducer(ActionState.SET_PENDING_EULAS, (state = initialState, action) => {
    return {
      ...state,
      eulaConfig: action.payload
    }
  })

  /**
   * To set fone alert config
   */
  onReducer(
    ActionState.SET_FONE_ALERT_CONFIG,
    (state = initialState, action) => {
      return {
        ...state,
        foneAlertConfig: action.payload
      }
    }
  )
}
