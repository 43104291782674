/**
 * List of all global  actions.
 */
export enum ActionState {
  GET_APP_CONFIG = "[core app] Get App Config",
  AUTO_LOGIN = "[core app] Auto Login",
  APP_LOGOUT = "[core app] App Logout",
  SET_APPLICATIONS = "[core app] Set Applications",
  SET_ACTIVE_APP = "[core app] Set Active App",
  SET_USER_TOKEN = "[core app] set user token",
  GET_COMPANY_ID = "[core app] get Company Id",
  FETCH_USER_INFO = "[core app] fetch user info",
  SET_USER_INFO = "[core app] set user info",
  SET_INTER_APP_DATA = "[core app] set inter app data",
  SET_LOADER_STATUS = "[core app] set loader status",
  SET_APP_TITLE = "[core app] set app title",
  SET_PAGE_TITLE = "[core app] set page title",
  SET_ACTIVE_MENU = "[core app] set active menu",
  SET_SETTINGS_MENU = "[core app] set settings menu",
  SET_SETTINGS_TYPE = "[core app] set settings type",
  SET_APPLICATION_MESSAGES = "[core app] set application messages",
  SET_APPLICATION_MESSAGES_SUCCESS = "[core app] set application messages success",
  SET_APP_LOGOUT_SUCCESS = "[core app] App Logout Success",
  GET_PENDING_EULAS = "[core app] get pending eulas",
  SET_PENDING_EULAS = "[core app] set pending eulas",
  SET_ID_TOKEN = "[core app] set id token",
  SET_FONE_ALERT_CONFIG = "[core app] set fone alert config"
}

/**
 * Initial state
 */
export const initialState = {
  applications: [],
  activeApp: null,
  userToken: null,
  companyId: null,
  userInfo: null,
  interAppData: null,
  showGlobalLoader: true,
  appTitle: null,
  pageTitle: null,
  mountedApps: [],
  activeMenu: null,
  settingsMenu: [],
  isSettingsPage: false,
  applicationMessages: {},
  eulaConfig: null,
  idToken: null,
  showWhiteLoader: false,
  environment: null,
  foneAlertConfig: {
    status: false,
    alertProps: null
  }
}
