import { legacy_createStore as createStore, applyMiddleware } from "redux"
import { ActionState } from "./state/core.state"

/**
 * Object to Store Effect and Reducer  Handler.
 */
const effectsHandlerObj = {}
const reducerHandlerObj = {}

interface Action {
  type: ActionState
  [key: string]: any
}

/**
 * To add Reducer handler to state management.
 * @param actionState : ActionState
 * @param callback
 */
export const onReducer = (actionState: ActionState, callback) => {
  reducerHandlerObj[actionState] = callback
}

/**
 * To handle specific Reducer for Every Action
 * @param state : redux state
 * @param action : redux action
 * @returns
 */
export const coreReducer = (state: any, action: Action) => {
  if (reducerHandlerObj[action.type]) {
    return reducerHandlerObj[action.type](state, action)
  }
  return state
}

/**
 * To add Effects handler to state management
 * @param actionState
 * @param callback
 */
export const onEffect = (actionState: ActionState, callback) => {
  effectsHandlerObj[actionState] = callback
}

/**
 * To specific Effect for Every Action
 * @param store
 * @returns
 */
export const effectMiddleware = (store) => (next) => (action: Action) => {
  if (effectsHandlerObj[action.type]) {
    effectsHandlerObj[action.type](store, action)
  } else {
    next(action)
  }
}

/**
 * creating MiddleWare for Effect
 */
export const middleware = applyMiddleware(effectMiddleware)

/**
 * To Initialize Application State Management Store .
 * @param effectFn
 * @param reducerFn
 * @param initialState
 * @returns
 */
export const createCoreStore = (effectFn, reducerFn, initialState) => {
  effectFn()
  reducerFn(initialState)
  return createStore(coreReducer, middleware)
}
